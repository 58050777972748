import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import Share from '../../../components/Share';
import ContactForm from '../../../components/ContactForm';
import Checkout from "../../../components/Checkout";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
      <Row mdxType="Row">
        <Column colMd={1} colLg={1} mdxType="Column">
        </Column>
        <Column colMd={6} colLg={10} mdxType="Column">
          <p>{`First run of Wizdough Second Entry Strategy`}</p>
        </Column>
        <br />
      </Row>
    </PageDescription>
    <Row mdxType="Row">
      <Column colMd={1} colLg={1} mdxType="Column">
      </Column>
      <Column colMd={6} colLg={10} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACNElEQVQoz1VSy24TMRSdD2kyD8/Y87A9Y887oQ0NTVKEIkCsgIoFEqg/ABtY0QWgFn75cG3IgsXRuffaPvflQDcN8jhGk6bIswyCcyjihjHyOXiaeRbEguI5ncui9HC+4zgM8eTxJQ67PQKla2SLBWSSICZEJJ5HEUqC94kTd0bxE7gQEHnuzx1zl0QqGNv+Ezw7Q+HFEoQkkFNGJ+rEQ7KdSBQ6/y+cgINLVpZUrZTgmUBVKQT9MKCuJDpjoLSGVAq2rmHI9j5dVsrZCpIeyFLDttZDKReTXrzrRszzIxLsB1T0aFqtvJhLYNsWmkSnaaKsFebV7IX6dYvtbY3aaox2hcLNUlc0dwFjOgz9jEBTFYwWYKxFwhJfVUFtZDyjmEG4jNDULQqbYvOZYf8rgTkyTC+okzcLmCsBFqYQIqf2JYKWqklJsOtasDiFljXKvEIaCzSqRSYTdGuLzacE+58pdncptt8iXN8LXN4t0Wy5F3RLUfQ2aIzFgtav2t5XYc9r6KlCs+dYvayx+RJi97XA7iHE4Z7h8MBw9SPC098c2+9U4U4gIUE3RzeCoKf5HWnLr8oK9fMEF9cVxmOO4R3DxYcK7c0Sm1uJ/m2M9Xvh0d/E2HyUsK8X0HOOWjW0kHOPQNG8ni2X2BcFYsahC4lCFMgSAVXWSCPHDY2AKuCVh7NV1SBeMGQpR+7/oqC2NQLTr9GMK5hhxkjoaVPDcMKEkc4cn/z/7NHdn9C1o/82bst/AJ6pVmc9fIBmAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "images mstsc ukGdsLQDLR",
            "title": "images mstsc ukGdsLQDLR",
            "src": "/static/6f301f45f4b8ae098cacef03c0fe6290/3cbba/mstsc_ukGdsLQDLR.png",
            "srcSet": ["/static/6f301f45f4b8ae098cacef03c0fe6290/7fc1e/mstsc_ukGdsLQDLR.png 288w", "/static/6f301f45f4b8ae098cacef03c0fe6290/a5df1/mstsc_ukGdsLQDLR.png 576w", "/static/6f301f45f4b8ae098cacef03c0fe6290/3cbba/mstsc_ukGdsLQDLR.png 1152w", "/static/6f301f45f4b8ae098cacef03c0fe6290/0b124/mstsc_ukGdsLQDLR.png 1728w", "/static/6f301f45f4b8ae098cacef03c0fe6290/4ea69/mstsc_ukGdsLQDLR.png 2304w", "/static/6f301f45f4b8ae098cacef03c0fe6290/a4b11/mstsc_ukGdsLQDLR.png 2560w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <p>{`We’re going to take a quick overview of the second entry strategy. This strategy is based on the second entry indicator. `}</p>
        <p>{`You can also watch the video below.`}</p>
        <Column colMd={3} colLg={3} mdxType="Column">
          <div style={{
            justifyContent: 'flex-end',
            display: "flex"
          }}>
            <br />
            <iframe id="ytplayer" type="text/html" src="https://www.youtube.com/embed/StWtgd4ToaU?playsinline=1" frameBorder="0" allowFullScreen />
          </div>
        </Column>
        <p>{`First of all, let me show you how to install it and make sure it runs. To install the indicator, you’ll need to go to the control center and open Tools - Import NinjaScript add-on. Find the file that you downloaded from the website (the zip file), select it, and answer any questions during the installation process. Next, you’ll go to your chart, right-click - strategies, and in the opened window, find the folder Wisdough and select Wisdough Second Entry Strategy. Double-click it to open the settings. On the right side, enable the strategy and click OK. `}</p>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABW0lEQVQoz5WTy07DMBBF8xsoceLEid/Oi4oCEki0H8CCLQv+gP9fXWbcVioClbI48iSjHN3JJMV+nLFfN0gxYV1vEUOEtRbzvORTa4NhGAhNtYYxljAZZx2897nH57qsKISUqOoaohYIPkA2EmVZQoiKEKipd07TnNfNt15VVSgk3eQHucnp+r6H6hQMJWtIzr1rYWnRHIV8wUJjaCxl8bzZwamQk3LvGlkWtm2b00kaXQqJmuobQYKy/jHuJU7iwsdALzTB2Ij5ZYG2Hk/BI905Gv2wjMNSLqFpgQ7OBRTT1uP1UWN7b7G8JbRth60ZENIASbVSHbruMixNaSIWFHMyeL/X+PyI2O4GiIpeAUmkbP8UneBFhpAQ44xiaHskr/EwDvBTT6JjKtVdLTwkHAkSsp3HFE2bPxNe0n/gZSqlcsIsTONEUWP+0hnnfj8z5/Wxx4QQMNIfx8Ivj8k5XA8ogMkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "images chrome EVin4zSnua",
            "title": "images chrome EVin4zSnua",
            "src": "/static/3c769831827ec7df79346a382b2e5585/3cbba/chrome_EVin4zSnua.png",
            "srcSet": ["/static/3c769831827ec7df79346a382b2e5585/7fc1e/chrome_EVin4zSnua.png 288w", "/static/3c769831827ec7df79346a382b2e5585/a5df1/chrome_EVin4zSnua.png 576w", "/static/3c769831827ec7df79346a382b2e5585/3cbba/chrome_EVin4zSnua.png 1152w", "/static/3c769831827ec7df79346a382b2e5585/0b124/chrome_EVin4zSnua.png 1728w", "/static/3c769831827ec7df79346a382b2e5585/ca4f5/chrome_EVin4zSnua.png 2052w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <p>{`To test how the strategy works, you can use Playback Connection. For Playback Connection, you’ll need to download market replay data. To do this, you’ll need to disconnect from the live connection and connect to the playback connection. You’ll see a small window controlling the playback. `}</p>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "802px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.91666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACb0lEQVQ4y3VTyW4aQRCdzzDMvu8bMCyeYbPBgAHLjpRDDj5EipRj/v/0UtXDBGzFh6fqrq5+Vd31Svo9bvDnfolf4xkcy4Zl27AFHJimJcBr+5+fYijONE1hxRlZx3HQNA0kP0wwqxsEUQxV1WAYJsGArusIwwgR+XnNvs5vWRbSNBPk1zMTmqZBSpMcy+UDynKEPB+gKIZizXaxWGO12qCqpuKM/YzRaIz5fIX6fo7hYIwiHyLLSpTFCFIQRLQYII4TBGEI13Upa1tJlhd0YYQgCOF53sXfVWqIZ3Oc6/qUZEKxFaQkyTAgQn4GB3Awl66qqrCmYYozhvBfzq5QoBsaXM+F4zqQcqpivVyBbUb/4jg2+v0+FEUmAhWy3Kd9j6wsfMrFtuRtUlt1YfSpUbINKaZPnzcLjKnkhP4zSXN6fkrIUM2WKMc1knICPxkiIIRZBS8qoSqqIDQ1GxN/hWmxwDRfQPI8X3QsiugP/RD8p2EYw/cjasgWx/0LzsdXnJ9fsab95mFPSQdUsSIIDc1C5c4xzYgwJULWWa/XPqmDoii4693hflLjvDvhsH3G6emIw+6I0/4Ml7THMd2zWW4KJ5BVSCzOrgFX6OLCbNbgcbPHdnvA09OzsLv9ibrqXQnprm7o9LJArL8g1ESlrLP39594e/2Ol/M3nE9vZN+EtDrCz5BYDnx4K4WWsC8q/PHjHXuq6vFxh83mIKrkMeOEH+XTQuKR6f7tFiydNC0wKCtMpzVNxhp1vRRT89UdhsRiZmZFUT80RUBu7W3D/pv8lpAnpSINhnEBxY5hWq6QDo/bFdEnfPSXJLPosv8Lq3i5vEf+82cAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "images chrome N5wqtNCiDy",
            "title": "images chrome N5wqtNCiDy",
            "src": "/static/095704224159860a6764a9a0a9f9588d/32d86/chrome_N5wqtNCiDy.png",
            "srcSet": ["/static/095704224159860a6764a9a0a9f9588d/7fc1e/chrome_N5wqtNCiDy.png 288w", "/static/095704224159860a6764a9a0a9f9588d/a5df1/chrome_N5wqtNCiDy.png 576w", "/static/095704224159860a6764a9a0a9f9588d/32d86/chrome_N5wqtNCiDy.png 802w"],
            "sizes": "(max-width: 802px) 100vw, 802px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <p>{`Sometimes when you change accounts or go offline, the account may be lost and the checkbox will become grayed out. In this case, you can turn on the strategy by going to the settings and checking that the account is selected.`}</p>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1124px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "92.36111111111111%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACQ0lEQVQ4y41UyXIaUQycz0hiCDVwMBh79n3fWCoHhzI3V46O4/z/B3QkwZsZ49jJoUvUe29aLamFFgYR1us1ZrMZdF2HPp9jTtD1ORaLBVarG6yWKzkbYzr9iqurCSYTxhSfPn+Bb1rQPC9AEufw/RABwTAs3N2ZAv7t031RVOB3t7dGf7fb7XE4HHB//13w8HBE23VE6PpwHR+GPDROihSWNzBNG3leyhvLcgS27eLx8Qeen3/h6emn4OXlN47HI7QwjBFHCSKKnufBtCz6yO7hOC6SJBOFTM5gUla7XHLiteD6ekVVBtC4LAX12DSdc7RFTVnWKPKqP+NY5LW0Ko6zHkVRQlNlDLD73/yx43goixpN01ElaZ8kDBMEQdzD92NkaQ5tUDWUc0lY162gqhoZEA/QoZ7atidwCJblksr0gvBCrSLs2o0MhvvIYAJWGIYp9Z4QZWcQoaOImNh8S8jlMRmDB8gJ+K6uN9huvqGhWOQNqrI7Ebr/IJSSqxYp9cd1B+uwwphVkcLwDN8LqWTr45JZoeqdsg7fZVlFZw1yimlaUaylAs36D4Wq5IDWtLcNkbX1lhzQEGEphBGpfk347lC2SMncYxf4fiRQtgmDRCp4NeW/EXLfdtu9mJvNf0k4JnbdEeGHCrutrB//Kag7PudkDO6zOtNOhh6MPTb4sHqNTJkNrQjLsqVEe7TtjvrZUo8bmbg2XrVLMBlnzbJC1o+jUsODYCRJcRoK7bb48D0yhmzF2chv19Ptk3Lk0rmCP/5kRCpINNmGAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "images chrome r7HjmZjF7m",
            "title": "images chrome r7HjmZjF7m",
            "src": "/static/e5ce7afc1c28eae6b130658e6fb4e9b2/3447d/chrome_r7HjmZjF7m.png",
            "srcSet": ["/static/e5ce7afc1c28eae6b130658e6fb4e9b2/7fc1e/chrome_r7HjmZjF7m.png 288w", "/static/e5ce7afc1c28eae6b130658e6fb4e9b2/a5df1/chrome_r7HjmZjF7m.png 576w", "/static/e5ce7afc1c28eae6b130658e6fb4e9b2/3447d/chrome_r7HjmZjF7m.png 1124w"],
            "sizes": "(max-width: 1124px) 100vw, 1124px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <p>{`Now that the strategy is turned on, we can see that the indicator is showing up and we have the buttons on the chart. To get the first entry, we’ll start the playback and speed it up a bit. When the entry is made, we’ll pause it. We can see that the strategy triggered in this place and decided to place an order. It was a market order and we got lucky to get filled exactly at this place. Sometimes the price can be slipped higher, so you can also use limit orders. We also see a blue triangle that shows where the profit target was and here are the second stop loss and first stop loss. `}</p>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACBElEQVQoz1VSy5LTMBD0Z3AgsV5WbMuS5cR2jDeQWh4Ju3nsArVQnKC48Av8fTMS2ZAcunosya2eHiWrZo5F12OxaNF1Hfq+jwh127aELu7FejFHU3s03qH3NYx1sNbCmgqrcYX7+z2SlHOwNAVjDCnxM8Jayog5Aw9nphMwY6H8HG6d4/HB4tCV0EKiHFr48RWqskKilIKUEoogT4i1Ik4FWK3xaUcOXjDInceHpcPD0SK9Mfjucnz7Qh0OHoe7W+pgIEGpIJjAlAsSExAkIrIMnG5OXwro2wqfNwWOKkP/Y8BQVRichlAaba6xXlks6x77t0cM69dIpsGy1xhLjcmEo36T44la2tUK268OzXsDNREYaL+oZshEBsEVtMyoE03xcLjZAvtfS/z5uUYyzDL4J4ujnWFHh+ebG2zGJbZjg8eDQWU0pCBHMnBGUfyDCFFlBMGhXI3xXYc7Tw4/ku3V1qGkw7+bCksSrF2LwtQ0CPpZZudsRYjkEkLQwCgWEjdFDU1DS6SeIac8JJfoqa3cKxJi8eaQ6TVIWFxzENVaw5JLaz2SoiiRlyUyEmZCxrYUHQjTj6ABRcTvi/qZaaiaakNPJgpKWuCMR+uCBMN0Qx351FJ4h1ffZ5bx36woUDULyrtGEqwaY1DQYkFOA0pyHZyX5X8+ozjhYs3QU7LOR4d/AdFxSigwpiENAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "images chrome VFcUnpszIE",
            "title": "images chrome VFcUnpszIE",
            "src": "/static/0e367056c888a74e6146c30872bed4ac/3cbba/chrome_VFcUnpszIE.png",
            "srcSet": ["/static/0e367056c888a74e6146c30872bed4ac/7fc1e/chrome_VFcUnpszIE.png 288w", "/static/0e367056c888a74e6146c30872bed4ac/a5df1/chrome_VFcUnpszIE.png 576w", "/static/0e367056c888a74e6146c30872bed4ac/3cbba/chrome_VFcUnpszIE.png 1152w", "/static/0e367056c888a74e6146c30872bed4ac/0b124/chrome_VFcUnpszIE.png 1728w", "/static/0e367056c888a74e6146c30872bed4ac/d7c9e/chrome_VFcUnpszIE.png 2054w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <p>{`To edit the settings of the strategy, we can go to the control center, uncheck it, right-click edit strategy, and change the settings. We can filter long and short, show or hide the indicator, and also show or hide the blue and pink plots that indicate where the profit target and stop loss were. We can also choose limit order and with limit order, we can also choose offset. `}</p>
        <p>{`Next, we have a section for historical and backtest. We can use up to four targets and in this setup, the first target is the scalp. We want to get three orders filled as soon as possible. The second target is a runner and it’s just one contract. We don’t want a profit target here at all because we are using break events. When the price moves up, the stop will be moved to the average fill price. We also have a risk management tool that lets us filter out small signal bars or high signal bars. This helps us to avoid situations where the stop is moved too close to the price and the price suddenly moves below the stop. `}</p>
        <p>{`Finally, we have the second entry indicator parameters. There are two filters that help us enter long only when we have a bullish trend and enter short only when we have a bearish trend. That’s probably it. Please let me know what you think about it and what questions you have. I will try to discuss them in the next video.`}</p>
        <p>{`I hope you will find this useful.`}</p>
        <p>{`Good trading!`}</p>
        <Share mdxType="Share" />
        <h3>{`Feedback`}</h3>
        <hr></hr>
      </Column>
    </Row>
    <br /><br />
    <Row mdxType="Row">
      <Column colMd={1} colLg={1} mdxType="Column">
      </Column>
      <Column colMd={3} colLg={6} mdxType="Column">
        <p>{`Have you got questions or feedback?`}</p>
        <p>{`Do you need technology or a new concept for the business? `}</p>
        <p>{`Contact us and our team will contact you back. `}</p>
      </Column>
      <Column colMd={3} colLg={4} mdxType="Column">
Please fill out the form below and let us talk to you about your idea. You will be kept confidential with your details.
        <br />
        <ContactForm mdxType="ContactForm" />
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      